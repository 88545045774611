import React, { Component } from 'react'
import { Alert, Button, Form, Row, Col } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';
import Modal from 'react-bootstrap/Modal'
import api from '../api/axios'

class Manifest extends Component {

  constructor(props){
    super(props)
    this.state = {
      showModal: false,
      manifestJSON: '',
      type: '',
      tag: '',
      resUUID: '',
      manifestStatus: null,
      clean: false,
      deploy: false
    }
    this.getManifestStatus()
  }

  render() {
    let appendBtnText = '';
    if (this.state.manifestStatus) {
      if (this.state.manifestStatus.Error) {
        appendBtnText = ' (error)'
      } else {
        appendBtnText = ' (working...)'
      }
    }
    return(
      <div style={{ float: 'right', marginLeft: 5 }}>
        <Button
          variant="outline-primary"
          onClick={() => {
            this.setState({ showModal: true })
          }}>
          Push Manifest / Tag{ appendBtnText }
        </Button>
        { this.renderModal() }
      </div>
    )
  }

  getManifestStatus () {
    this.setState({ fetchingStatus: true })
    api.get('/pkgs/add/manifest')
      .then(res => {
        if (res.data) {
          setTimeout(() => {
            this.getManifestStatus()
          }, 2000)
        } else {
          if (this.state.manifestStatus) {
            try {
              this.props.onComplete()
            } catch (e) {}
          }
        }
        this.setState({ manifestStatus: res.data || null, fetchingStatus: false })
      })
      .catch(err => {
        NotificationManager.warning('Unable to check manifest status');
        this.setState({ fetchingStatus: false })
      })
  }

  putManifest () {
    let clean = this.state.clean.toString()
    let deploy = this.state.deploy.toString()
    let url = `/pkgs/add/manifest?clean=${clean}&deploy=${deploy}`
    api.put(url, this.state.manifestJSON)
      .then(res => {
        this.setState({ showModal: false, resUUID: res.data.UUID })
        this.getManifestStatus()
        NotificationManager.success('Pushing manifest...');
      })
      .catch(err => {
        console.log('err.response', err.response)
        let str = 'Error pushing manifest'
        if (err && err.response && err.response.data) {
          str += err.response.data
        }
        NotificationManager.warning(str);
      })
  }

  putTag () {
    let clean = this.state.clean.toString()
    let deploy = this.state.deploy.toString()
    let url = `/pkgs/add/manifest/${this.state.type}/${this.state.tag}?clean=${clean}&deploy=${deploy}`
    api.put(url)
      .then(res => {
        this.setState({ showModal: false })
        this.getManifestStatus()
        NotificationManager.success('Pushing tag...');
      })
      .catch(err => {
        console.log('err.response', err.response)
        let str = 'Error pushing tag'
        if (err && err.response && err.response.data) {
          str += err.response.data
        }
        NotificationManager.warning(str);
      })
  }

  stageFrom () {
    if (this.state.manifestJSON) {
      return 'Manifest'
    } else if (this.state.type && this.state.tag) {
      return 'Tag'
    }
    return ''
  }

  stage () {
    if (this.state.manifestJSON) {
      this.putManifest()
    } else {
      this.putTag()
    }
  }

  renderStatus () {
    if (!this.state.manifestStatus) {
      return null
    }
    return <>
      <Alert variant="primary">
        <strong>Currently processing task with UUID: { this.state.manifestStatus.UUID }</strong>
        {
          this.state.manifestStatus.Error
            ? (
              <span>Error: { this.state.manifestStatus.Error }</span>
            ) : null
        }
      </Alert>
      <hr />
    </>
  }

  renderModal () {
    return (
      <Modal
        size='lg'
        show={this.state.showModal}
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title className='h5'>
            Push Manifest or Tag
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.renderStatus() }
          <Row>
            <Col>
              <h4>Manifest:</h4>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="4"
                  placeholder="Manifest JSON"
                  onChange={(e) => {
                    this.setState({ manifestJSON: e.target.value })
                  }} />
              </Form.Group>
            </Col>
            <Col>
              <h4>Tag:</h4>
              <Form.Group className="mb-3" controlId="">
                  <Form.Control as="select" onChange={ (e) => {
                      this.setState({ type: e.target.value })
                  }}>
                      <option value="" key="">Select type</option>
                      <option value="nex7" key="">Nex7</option>
                      <option value="first-floor" key="">First Floor</option>
                  </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="">
                  <Form.Control type="text" placeholder="Tag" onChange={(e) => this.setState({ tag: e.target.value })} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr />
              <Form.Group className="mb-3">
                <Form.Check type="checkbox" label="Clean staged apps" checked={this.state.clean} onChange={(e) => this.setState({clean: !this.state.clean})} />
                <Form.Check type="checkbox" label="Deploy" checked={this.state.deploy} onChange={(e) => this.setState({deploy: !this.state.deploy})} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' size='sm' disabled={!this.stageFrom()} onClick={() => { this.stage() }}>Push {this.stageFrom()}</Button>
          <Button variant='secondary' size='sm' onClick={() => { this.setState({ showModal: false }) }}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default Manifest
